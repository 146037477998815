<template>
  <b-card no-body class="menu-card overflow-hidden">
    <b-row no-gutters cols="2">
      <b-col cols="3" class="icon-column">
        <b-card-img :src="iconSrc" alt="menu-icon"></b-card-img>
      </b-col>
      <b-col cols="9">
        <b-card-body>
          <b-card-text class="title-text d-flex justify-content-center">
            {{ title }}
          </b-card-text>
          <b-card-text class="menu-text d-flex justify-content-center">
            {{ text }}
          </b-card-text>
        </b-card-body>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: "MainContentMenu",
  props: ["iconSrc", "title", "text"],
};
</script>

<style scoped>
.icon-column {
  align-self: center;
  text-align: right;
}
.card-img {
  width: 75%;
}
.menu-card {
  width: 290px;
  background-color: #b6a496;
}
.title-text {
  font-size: 2.4rem;
  font-weight: bold;
  color: #ffffff;
}
.menu-text {
  font-size: 1.2rem;
  color: #ffffff;
}
</style>
