<template>
  <div class="information-space">
    <b-container class="main-container">
      <information-space-item
        date="2023/12/8"
        information="会社情報を更新しました。"
      />
      <information-space-item
        date="2023/3/5"
        information="ホームページをリニューアルしました。"
      />
    </b-container>
  </div>
</template>

<script>
import InformationSpaceItem from "./InformationSpaceItem.vue";
export default {
  name: "InformationSpace",
  components: {
    InformationSpaceItem,
  },
};
</script>

<style scoped>
.information-space {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.main-container {
  max-width: 70%;
}
</style>
