<template>
  <div class="main-contents">
    <b-container class="main-container">
      <b-row class="content-card-row" cols="1" cols-sm="1" cols-md="2" cols-lg="3">
        <b-col class="mb-3 d-flex justify-content-center">
          <a href="greeting.html">
            <main-content-menu icon-src="../assets/menu-icon-greeting.png" title="Greeting" text="ご挨拶" />
          </a>
        </b-col>
        <b-col class="mb-3 d-flex justify-content-center">
          <a href="philosophy.html">
            <main-content-menu icon-src="../assets/menu-icon-philosophy.png" title="Philosophy" text="経営理念" />
          </a>
        </b-col>
        <b-col class="mb-3 d-flex justify-content-center">
          <a href="services.html">
            <main-content-menu icon-src="../assets/menu-icon-services.png" title="Services" text="事業内容" />
          </a>
        </b-col>
        <b-col class="mb-3 d-flex justify-content-center">
          <a href="cases.html">
            <main-content-menu icon-src="../assets/menu-icon-cases.png" title="Cases" text="施工事例" />
          </a>
        </b-col>
        <b-col class="mb-3 d-flex justify-content-center">
          <a href="company.html">
            <main-content-menu icon-src="../assets/menu-icon-company.png" title="Company" text="会社情報" />
          </a>
        </b-col>
        <b-col class="mb-3 d-flex justify-content-center">
          <a href="inquiry.html">
            <main-content-menu icon-src="../assets/menu-icon-inquiry.png" title="Inquiry" text="お問い合わせ" />
          </a>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import MainContentMenu from "./MainContentMenu.vue";
export default {
  name: "MainContents",
  components: {
    MainContentMenu,
  },
};
</script>

<style scoped>
.main-contents {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.main-container {
  max-width: 960px;
}
</style>
