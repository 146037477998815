<template>
  <b-card no-body class="menu-card overflow-hidden">
    <b-row no-gutters cols-xs="1" cols-sm="2">
      <b-col cols="12" sm="2">
        <b-card-body class="date-body">
          <b-card-text class="date-text d-flex justify-content-left">
            {{ date }}
          </b-card-text>
        </b-card-body>
      </b-col>
      <b-col cols="12" sm="10">
        <b-card-body class="information-body">
          <b-card-text class="information-text d-flex justify-content-left">
            {{ information }}
          </b-card-text>
        </b-card-body>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  name: "InformationSpaceItem",
  props: ["date", "information"],
};
</script>

<style scoped>
.date-body {
  padding: 0 0 0 0;
}
.information-body {
  padding: 0 0 0 1.5rem;
}
</style>
