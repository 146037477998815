<template>
  <div id="app">
    <global-header />
    <hero-space />
    <information-space />
    <main-contents />
    <global-footer />
  </div>
</template>

<script>
import GlobalFooter from "../../components/GlobalFooter.vue";
import GlobalHeader from "../../components/GlobalHeader.vue";
import HeroSpace from "../../components/HeroSpace.vue";
import InformationSpace from "../../components/InformationSpace.vue";
import MainContents from "../../components/MainContents.vue";

export default {
  name: "app",
  components: {
    GlobalHeader,
    HeroSpace,
    InformationSpace,
    MainContents,
    GlobalFooter,
  },
};
</script>

<style scoped>
@media only screen and (max-width: 750px) {
  html {
    font-size: 62.5%;
  }
}
@media only screen and (min-width: 750px) {
  html {
    font-size: 75%;
  }
}
#app,
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
</style>
