<template>
  <div class="global-header">
    <b-navbar class="navbar-brand-row flex-column" toggleable="lg" type="dark">
      <b-navbar-brand class="mr-auto" href="index.html"> <b-img class="img-fluid" src="../../public/campany_logo.png"></b-img></b-navbar-brand>
    </b-navbar>

    <b-navbar class="navbar-menu-row flex-column" toggleable="sm" type="dark">
      <b-navbar-toggle class="ml-auto" target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="justify-content-md-around">
          <b-nav-item href="services.html">事業内容</b-nav-item>
          <b-nav-item href="cases.html">施工事例</b-nav-item>
          <b-nav-item href="company.html">会社情報</b-nav-item>
          <b-nav-item href="inquiry.html">お問い合わせ</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-navbar class="navbar-phone-row" type="dark">TEL: 075-574-7247</b-navbar>
  </div>
</template>

<script>
export default {
  name: "GlobalHeader",
};
</script>

<style scoped>
.navbar-brand-row {
  background-color: #000000;
}
.navbar-phone-row {
  justify-content: center;
  padding: 3px;
}
@media only screen and (max-width: 420px) {
  .navbar-brand {
    font-size: 1.5rem;
  }
  .navbar-menu-row {
    font-size: 1rem;
    background-color: #51050f;
  }
  .navbar-phone-row {
    font-size: 1.1rem;
    background-color: #51050f;
  }
}
@media only screen and (min-width: 420px) {
  .navbar-brand {
    font-size: 1.8rem;
  }
  .navbar-menu-row {
    font-size: 1.1rem;
    background-color: #51050f;
  }
  .navbar-phone-row {
    font-size: 1.1rem;
    background-color: #51050f;
  }
}
@media only screen and (min-width: 750px) {
  .navbar-brand {
    font-size: 2.4rem;
  }
  .navbar-menu-row,
  .navbar-phone-row {
    font-size: 1.4rem;
    background-color: #51050f;
  }
}
.navbar-toggler {
  padding: 0.8rem 1.6rem; /* toggle icon size */
}
.navbar-dark .navbar-brand {
  color: rgba(255, 255, 255, 0.89);
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.89);
}
.navbar-phone-row.navbar-dark.navbar-expand {
  color: rgba(255, 255, 255, 0.89);
}
</style>
